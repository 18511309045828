import Vue from 'vue';
import VueRouter from 'vue-router';
// data
import { isLocalDevelopment } from '@/local-settings';

// store
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: isLocalDevelopment ? '/proposals' : '/auth/login',
    meta: {
      isAuthenticated: false
    },
  },
  {
    path: '/auth/login',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/login'),
        name: 'Login',
        meta: { isAuthenticated: false },
      },
      {
        path: 'webhook',
        component: () => import('@/views/login/LoginWebhook'),
        name: 'LoginWebhook',
        meta: { isAuthenticated: false },
      },
      {
        path: 'processing',
        component: () => import('@/views/login/authLoader.vue'),
        name: 'AuthLoader',
        meta: { isAuthenticated: false },
      },
    ],
  },
  {
    path: '/proposals/:id/output',
    component: () => import('@/views/proposals/proposal-output/ProposalOutput.vue'),
    name: 'ExportViewNo',
    meta: { isAuthenticated: true },
  },
  {
    path: '',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: 'proposals',
        component: () => import('@/views/proposals/proposals-list/ProposalsList.vue'),
        name: 'ProposalList',
        meta: { isAuthenticated: true },
      },
      {
        path: 'proposals/create',
        component: () => import('@/views/proposals/proposal-create/CreateProposal.vue'),
        name: 'ProposalCreate',
        meta: { isAuthenticated: true },
      },
      {
        path: 'proposals/:id/edit',
        component: () => import('@/views/proposals/proposal-edit/EditProposal.vue'),
        name: 'ProposalEdit',
        meta: { isAuthenticated: true },
      },
      {
        path: 'proposals/:id/copy',
        component: () => import('@/views/proposals/proposal-copy/CopyProposal.vue'),
        name: 'ProposalCopy',
        meta: { isAuthenticated: true },
      },
      {
        path: 'users',
        component: () => import('@/views/users/UsersList.vue'),
        name: 'UsersList',
        meta: { isAuthenticated: true, adminRequired: true },
      },
      {
        path: 'users/create',
        component: () => import('@/views/users/UserCreate.vue'),
        name: 'UserCreate',
        meta: { isAuthenticated: true, adminRequired: true },
      },
      {
        path: 'users/edit/:id',
        component: () => import('@/views/users/UserEdit.vue'),
        name: 'UserEdit',
        meta: { isAuthenticated: true, adminRequired: true },
      },
    ],
  },
  {
    path: '/403',
    component: () => import('@/views/403'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (isLocalDevelopment) {
    setFakeUserData(to);
    next();
  } else {
    const authenticatedUser = store.state.user;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !authenticatedUser) {
      next('/auth/login')
    } else {
      next();
    }
  }
});
export default router;

function setFakeUserData() {
  const loginData = {
    role: 'admin',
    token: '-',
    refToken: '-',
    userId: 1,
    userName: 'John Doe',
    email: 'johndoe@worldinsurence.com',
  };
  store.commit('setUser',  loginData);
}