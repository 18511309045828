import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/services/axios';
import VuexPersistence from 'vuex-persist';
// store
import {nonPersistentStore} from '@/store/non-persistent.store';
// hooks
import {defaultDataHooks} from '@/hooks/defaultData.hooks';
import {calculateDataHooks} from '@/hooks/calculateData.hooks';
import {storeHooks} from '@/hooks/store.hooks';
import {CustomerService} from "@/services/domain/costumer.service";
import {WipService} from "@/services/domain/wip.service";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    user: {
      role: '',
      token: '',
      refToken: '',
      userId: 0,
      userName: '',
      email: '',
    },
    programList: [],
    riskMatchData: {},
    adminList: [],
    outputData: {},
    inputForm: CustomerService.initData(),
    input_form_edit: CustomerService.initData(),
    input_form_copy: CustomerService.initData(),
    pop_health_dial: defaultDataHooks.popHealthDials.pop_health_dial(),
    pop_health_dial_edit: defaultDataHooks.popHealthDials.pop_health_dial(),
    pop_health_dial_yes: defaultDataHooks.popHealthDials.pop_health_dial(),
    pop_health_dial_copy: defaultDataHooks.popHealthDials.pop_health_dial(),
    programConstants: {
      self_funded: defaultDataHooks.programs.getEmptyProgramConstants(),
      center_of_excellence_cancer: defaultDataHooks.programs.getEmptyProgramConstants(),
      center_of_excellence_surgical: defaultDataHooks.programs.getEmptyProgramConstants(),
      high_performing_network: defaultDataHooks.programs.getEmptyProgramConstants(),
      patient_assistance_program: defaultDataHooks.programs.getEmptyProgramConstants(),
      pharmacy_carve_out: defaultDataHooks.programs.getEmptyProgramConstants(),
      reinsurance_carve_out: defaultDataHooks.programs.getEmptyProgramConstants(),
      chronic_care_management_diabetes: defaultDataHooks.programs.getEmptyProgramConstants(),
      chronic_care_management_hypertension: defaultDataHooks.programs.getEmptyProgramConstants(),
      chronic_care_management_msk: defaultDataHooks.programs.getEmptyProgramConstants(),
      health_care_navigation: defaultDataHooks.programs.getEmptyProgramConstants(),
      enhanced_mh_sa_services: defaultDataHooks.programs.getEmptyProgramConstants(),
      maternity_care_management: defaultDataHooks.programs.getEmptyProgramConstants(),
      physician_engagement: defaultDataHooks.programs.getEmptyProgramConstants(),
      incentivized_plan_design: defaultDataHooks.programs.getEmptyProgramConstants(),
    },
    program: {
      convert_to_self_funded_y3_claims_savings: 0,
      convert_to_self_funded_Y3_fixed: 0,
      convert_to_self_funded_Y1_fixed: 0,
      centers_of_excellence_cancer_claims_savings: 0,
      centers_of_excellence_cancer_fixed: 0,
      centers_of_excellence_surgical_claims_savings: 0,
      centers_of_excellence_surgical_fixed: 0,
      centers_of_excellence_surgical_Y3_claims_savings: 0,
      centers_of_excellence_surgical_y3_fixed: 0,
      high_performing_network_claims_savings: 0,
      patient_assistance_program_claims_savings: 0,
      patient_assistance_program_fixed: 0,
      pharmacy_carve_out_claims_savings: 0,
      reinsurance_carve_out_fixed: 0,
      reinsurance_carve_out_Y2_fixed: 0,
      case_management_carve_out_claim_savings: 0,
      case_management_carve_out_fixed: 0,
      chronic_care_management_diabetes_claims_savings: 0,
      chronic_care_management_diabetes_fixed: 0,
      chronic_care_management_diabetes_Y2_claims_savings: 0,
      chronic_care_management_diabetes_Y2_fixed: 0,
      chronic_care_management_diabetes_Y2_prevalence: 0,
      // chronic_care_management_hypertension_claim_savings: 0.11,
      chronic_care_management_hypertension_claims_savings: 0,
      chronic_care_management_hypertension_fixed: 0,
      chronic_care_management_hypertension_prevalence: 0,
      chronic_care_management_msk_claims_savings: 0,
      chronic_care_management_msk_fixed: 0,
      chronic_care_management_msk_prevalence: 0,
      customized_care_navigation_claims_savings: 0,
      customized_care_navigation_fixed: 0,
      digital_wearable_claim_savings: 0,
      digital_wearable_fixed: 0,
      enhanced_mh_sa_services_claims_savings: 0,
      enhanced_mh_sa_services_fixed: 0,
      fertility_services_claims_savings: 0,
      fertility_services_fixed: 0,
      health_care_navigation_claims_savings: 0,
      health_care_navigation_fixed: 0,
      maternity_care_management_claims_savings: 0,
      maternity_care_management_fixed: 0,
      incentivized_plan_design_claims_savings: 0,
      incentivized_plan_design_fixed: 0,
      physician_engagement_claims_savings: 0,
      physician_engagement_fixed: 0,
    },
    calc: defaultDataHooks.popHealthDials.getColumnsWithDials(),
    calc_edit: defaultDataHooks.popHealthDials.getColumnsWithDials(),
    calc_yes: defaultDataHooks.popHealthDials.getColumnsWithDials(),
    calc_copy: defaultDataHooks.popHealthDials.getColumnsWithDials(),
    wip_graph_form: WipService.InitFormData(true),
    wip_graph_form_edit: WipService.InitFormData(true),
    wip_graph_form_yes: WipService.InitFormData(true),
    wip_graph_form_copy: WipService.InitFormData(true),
    input_claims_detail: {
      baseline_trend: 0.075,
      cost_mitigation_trend: 0.075,
      is_medical_split_available: "No",
      is_professional_split_available: "No",
      is_claims_split_available: "No",
      claims_cost_inputs: {
        c: {
          inpatient_claims: "",
          outpatient_claims: "",
          professional_claims: "",
          total_medical_claims: "",
          prescription_drug_claims: "",
          total_claims: 3907500,
          carrier_retention: 120000,
          estimated_annual_total_cost: 5107500,
        },
        d: {
          inpatient_claims: 0.27,
          outpatient_claims: 0.18,
          professional_claims: 0.34,
          total_medical_claims: 0.79,
          prescription_drug_claims: 0.21,
          total_claims: 1,
          carrier_retention: "",
          estimated_annual_total_cost: "",
        },
        e: {
          inpatient_claims: 0.213,
          outpatient_claims: 0.142,
          professional_claims: 0.268222222,
          total_medical_claims: 0.62,
          prescription_drug_claims: 0.165666667,
          total_claims: 0.79,
          carrier_retention: 0.211111111,
          estimated_annual_total_cost: 1,
        },
      },
      claims_cost: {
        c: {
          inpatient_claims: 1087898,
          outpatient_claims: 725265,
          professional_claims: 1369945,
          total_medical_claims: 3183108,
          prescription_drug_claims: 846143,
          total_claims: 4029250,
          carrier_retention: 1078250,
          estimated_annual_total_cost: 5107500,
        },
        e: {
          inpatient_claims: 0.213,
          outpatient_claims: 0.142,
          professional_claims: 0.268222222,
          total_medical_claims: 0.623222222,
          prescription_drug_claims: 0.165666667,
          total_claims: 0.79,
          carrier_retention: 0.211111111,
          estimated_annual_total_cost: 1,
        },
      },
    },
    underwriting: {
      ...defaultDataHooks.underwriting.get(),
      trend: {
        medical1: 7,
        rx1: 10,
        months: "",
        medical2: 0,
        rx2: 0,
      },
    },
    underwriting_edit: {
      ...defaultDataHooks.underwriting.get(),
      trend: {
        medical1: 7,
        rx1: 10,
        months: "",
        medical2: 0,
        rx2: 0,
      },
      id: {
        id1: 296,
        id2: 297,
        id3: 298,
        id4: 299,
        id5: 300,
        id6: 301,
        id7: 302,
        id8: 303,
        id9: 304,
        id10: 305,
        id11: 306,
        id12: 307,
      },
    },
    underwriting_copy: {
      ...defaultDataHooks.underwriting.get(),
      trend: {
        medical1: 7,
        rx1: 10,
        months: "",
        medical2: 0,
        rx2: 0,
      },
    },
    assumptions: {
      loss_ratio: 0,
      inpatient: 0,
      outpatient: 0,
      professional: 0,
      rx: 0,
    },
    contributions: {
      planNames: {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      },
      contribution_structure: "4",
      cols: {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      },
      baseline: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      dc: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      er_parcentage: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
    },
    contributions_edit: {
      planNames: {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      },
      contribution_structure: "4",
      cols: {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      },
      baseline: {
        plan1: {
          id: "",
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          id: "",
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          id: "",
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          id: "",
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          id: "",
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      dc: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      er_parcentage: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
    },
    contributions_copy: {
      planNames: {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      },
      contribution_structure: "4",
      cols: {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      },
      baseline: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      dc: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
      er_parcentage: {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      },
    },
  },
  getters: {
    getInputFormClaimAvailable: (state) => {
      return state.inputForm.claims_data_available;
    },
  },
  mutations: {
    userLogOut(state) {
      state.user = "";
    },
    setUser(state, user) {
      state.user = user;
    },
    setRiskMatchData(state, payload) {
      state.riskMatchData = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setAdminData(state, payload) {
      state.adminList = payload;
    },
    setAssumptionProgramData(state, payload) {
      state.assumptions = payload.assumptions;
      state.program = payload.program; // this should be moved to setProgramConstants
    },
    setProgramConstants(state, payload) {

      // trendBend => Y5:Y18

      // self_funded
      state.programConstants.self_funded.fixed = payload.convert_to_self_funded_Y1_fixed;
      state.programConstants.self_funded.fixedY3 = payload.convert_to_self_funded_Y3_fixed;
      state.programConstants.self_funded.claimsSavings = payload.convert_to_self_funded_Y1_claims_savings;
      state.programConstants.self_funded.claimsSavingsY3 = payload.convert_to_self_funded_Y3_claims_savings;
      // center_of_excellence_cancer
      state.programConstants.center_of_excellence_cancer.fixed = payload.centers_of_excellence_cancer_fixed;
      state.programConstants.center_of_excellence_cancer.claimsSavings = payload.centers_of_excellence_cancer_claims_savings;
      state.programConstants.center_of_excellence_cancer.engagement = 0.25;
      state.programConstants.center_of_excellence_cancer.trendBend = 0.125;
      // center_of_excellence_surgical
      state.programConstants.center_of_excellence_surgical.fixed = payload.centers_of_excellence_surgical_Y1_fixed;
      state.programConstants.center_of_excellence_surgical.fixedY3 = payload.centers_of_excellence_surgical_Y3_fixed;
      state.programConstants.center_of_excellence_surgical.claimsSavings = payload.centers_of_excellence_surgical_claims_savings;
      state.programConstants.center_of_excellence_surgical.claimsSavingsY3 = payload.centers_of_excellence_surgical_Y3_claims_savings;
      state.programConstants.center_of_excellence_surgical.netRoi = 425.7;
      state.programConstants.center_of_excellence_surgical.trendBend = 0.125;
      // high_performing_network
      state.programConstants.high_performing_network.fixed = payload.high_performing_network_fixed;
      state.programConstants.high_performing_network.claimsSavings = payload.high_performing_network_claims_savings;
      state.programConstants.high_performing_network.netRoi = 344.4;
      state.programConstants.high_performing_network.trendBend = 0.250;
      // patient_assistance_program
      state.programConstants.patient_assistance_program.fixed = payload.patient_assistance_program_fixed;
      state.programConstants.patient_assistance_program.claimsSavings = payload.patient_assistance_program_claims_savings;
      // pharmacy_carve_out
      state.programConstants.pharmacy_carve_out.fixed = payload.pharmacy_carve_out_fixed;
      state.programConstants.pharmacy_carve_out.claimsSavings = payload.pharmacy_carve_out_claims_savings;
      state.programConstants.pharmacy_carve_out.trendBend = 0.250;
      // reinsurance_carve_out
      state.programConstants.reinsurance_carve_out.fixed = payload.reinsurance_carve_out_Y1_fixed;
      state.programConstants.reinsurance_carve_out.fixedY2 = payload.reinsurance_carve_out_Y2_fixed;
      state.programConstants.reinsurance_carve_out.claimsSavings = payload.reinsurance_carve_out_claims_savings;
      state.programConstants.reinsurance_carve_out.claimsSavingsY2 = payload.reinsurance_carve_out_Y2_claims_savings;
      // chronic_care_management_diabetes
      state.programConstants.chronic_care_management_diabetes.fixed = payload.chronic_care_management_diabetes_Y1_fixed;
      state.programConstants.chronic_care_management_diabetes.fixedY2 = payload.chronic_care_management_diabetes_Y2_fixed;
      state.programConstants.chronic_care_management_diabetes.claimsSavings = payload.chronic_care_management_diabetes_claims_savings;
      state.programConstants.chronic_care_management_diabetes.claimsSavingsY2 = payload.chronic_care_management_diabetes_Y2_claims_savings;
      state.programConstants.chronic_care_management_diabetes.prevalence = payload.chronic_care_management_diabetes_Y2_prevalence;
      state.programConstants.chronic_care_management_diabetes.engagement = 0.34;
      state.programConstants.chronic_care_management_diabetes.netRoi = 217.1;
      state.programConstants.chronic_care_management_diabetes.trendBend = 0.125;

      // chronic_care_management_hypertension
      state.programConstants.chronic_care_management_hypertension.fixed = payload.chronic_care_management_hypertension_fixed;
      state.programConstants.chronic_care_management_hypertension.claimsSavings = payload.chronic_care_management_hypertension_claims_savings;
      state.programConstants.chronic_care_management_hypertension.prevalence = payload.chronic_care_management_hypertension_prevalence;
      state.programConstants.chronic_care_management_hypertension.engagement = 0.34;
      state.programConstants.chronic_care_management_hypertension.netRoi = 76.7;
      state.programConstants.chronic_care_management_hypertension.trendBend = 0.125;
      // chronic_care_management_msk
      state.programConstants.chronic_care_management_msk.fixed = payload.chronic_care_management_msk_fixed;
      state.programConstants.chronic_care_management_msk.claimsSavings = payload.chronic_care_management_msk_claims_savings;
      state.programConstants.chronic_care_management_msk.prevalence = payload.chronic_care_management_msk_prevalence;
      state.programConstants.chronic_care_management_msk.engagement = 0.05;
      state.programConstants.chronic_care_management_msk.netRoi = 50;
      // health_care_navigation
      state.programConstants.health_care_navigation.fixed = payload.health_care_navigation_fixed;
      state.programConstants.health_care_navigation.fixedY2 = payload.customized_care_navigation_Y2_fixed;
      state.programConstants.health_care_navigation.fixedY3 = payload.customized_care_navigation_Y3_fixed;
      state.programConstants.health_care_navigation.claimsSavings = payload.health_care_navigation_claims_savings;
      state.programConstants.health_care_navigation.claimsSavingsY2 = payload.customized_care_navigation_Y2_claims_savings;
      state.programConstants.health_care_navigation.claimsSavingsY3 = payload.customized_care_navigation_Y3_claims_savings;
      state.programConstants.health_care_navigation.netRoi = 114.1;
      state.programConstants.health_care_navigation.trendBend = 0.125;
      // enhanced_mh_sa_services
      state.programConstants.enhanced_mh_sa_services.fixed = payload.enhanced_mh_sa_services_fixed;
      state.programConstants.enhanced_mh_sa_services.claimsSavings = payload.enhanced_mh_sa_services_claims_savings;
      // maternity_care_management
      state.programConstants.maternity_care_management.fixed = payload.maternity_care_management_fixed;
      state.programConstants.maternity_care_management.fixedY3 = payload.maternity_care_management_Y3_fixed;
      state.programConstants.maternity_care_management.claimsSavings = payload.maternity_care_management_claims_savings;
      // physician_engagement
      state.programConstants.physician_engagement.fixed = payload.physician_engagement_Y1_fixed;
      state.programConstants.physician_engagement.fixedY2 = payload.physician_engagement_Y2_fixed;
      state.programConstants.physician_engagement.fixedY3 = payload.physician_engagement_Y3_fixed;
      state.programConstants.physician_engagement.claimsSavings = payload.physician_engagement_Y1_claims_savings;
      state.programConstants.physician_engagement.claimsSavingsY2 = payload.physician_engagement_Y2_claims_savings;
      state.programConstants.physician_engagement.claimsSavingsY3 = payload.physician_engagement_Y3_claims_savings;
      state.programConstants.physician_engagement.netRoi = 50;
      state.programConstants.physician_engagement.trendBend = 0.125;
      // incentivized_plan_design
      state.programConstants.incentivized_plan_design.fixed = payload.incentivized_plan_design_fixed;
      state.programConstants.incentivized_plan_design.claimsSavings = payload.incentivized_plan_design_claims_savings;
      state.programConstants.incentivized_plan_design.engagement = 0.33;
      state.programConstants.incentivized_plan_design.netRoi = 482.4;
      state.programConstants.incentivized_plan_design.trendBend = 0.250;
    },
    setProgramList(state, payload) {
      state.programList = payload;
    },
    setOutputData(state, payload) {
      state.outputData = payload;
    },
    setFormData(state, payload) {
      // debugger;
      state.inputForm.industryList = payload.industries;
      state.input_form_edit.industryList = payload.industries;
      state.input_form_copy.industryList = payload.industries;

      state.inputForm.fundingList = payload.fundingType;
      state.input_form_edit.fundingList = payload.fundingType;
      state.input_form_copy.fundingList = payload.fundingType;

      state.inputForm.clientOrProspect = payload.clientOrProspect;
      state.input_form_edit.clientOrProspect = payload.clientOrProspect;
      state.input_form_copy.clientOrProspect = payload.clientOrProspect;

      state.inputForm.productList = payload.productType;
      state.input_form_edit.productList = payload.productType;
      state.input_form_copy.productList = payload.productType;
    },
    setInputFormData(state, payload) {
      // debugger;
      state.inputForm = payload;
    },
    setHealthDialData(state, payload) {
      state.pop_health_dial = payload.pop_health_dial;
      state.calc = payload.calc;
      state.wip_graph_form = payload.wip_graph_form;
    },
    setHealthDialDataYes(state, payload) {
      state.pop_health_dial_yes = payload.pop_health_dial;
      state.calc_yes = payload.calc;
      state.wip_graph_form_yes = payload.wip_graph_form;
    },
    setPlanNames(state, payload) {
      state.contributions.planNames = payload;
    },
    setColumnNames(state, payload) {
      state.contributions.cols = payload.cols;
      state.contributions.contribution_structure =
        payload.contributionStructure;
    },
    setBaselineData(state, payload) {
      state.contributions.baseline = payload;
    },
    setUnderwritingData(state, payload) {
      state.underwriting = payload;
    },
    setDCData(state, payload) {
      state.contributions_edit.dc = payload;
    },
    setEr_ParcentageData(state, payload) {
      state.contributions_edit.er_parcentage = payload;
    },
    resetPopHealthDialForm(state, payload) {
      state.calc = defaultDataHooks.popHealthDials.getColumnsWithDials();
      state.pop_health_dial = {
        fully_insured_total_cost: 0,
        self_funded_total_cost: 0,
        additonal_programs_total_cost: 0,
        completedSteps: 0,
        totalSteps: 0,
        q: {
          center_of_excellence_cancer: "No",
          center_of_excellence_surgical: "No",
          high_performing_network: "No",
          patient_assistance_program: "No",
          pharmacy_carve_out: "No",
          reinsurance_carve_out: "No",
          chronic_care_management_diabetes: "No",
          chronic_care_management_hypertension: "No",
          chronic_care_management_msk: "No",
          enhanced_mh_sa_services: "No",
          health_care_navigation: "No",
          maternity_care_management: "No",
          incentivized_plan_design: "No",
          physician_engagement: "No",
        },
        graph_percentage: 0,
      };
    },
    resetProposalForm(state, actionType) {
      storeHooks.validateActionType(actionType);
      if (actionType === 'create') {
        this.commit('resetInputForm');
        this.commit('resetBaselineForm');
        this.commit('resetUnderwritingForm');
      }
      if (actionType === 'edit') {
        this.commit('resetInputFormEdit');
        this.commit('resetBaselineFormEdit');
        this.commit('resetUnderwritingFormEdit');
      }
      if (actionType === 'copy') {
        this.commit('resetInputFormCopy');
        this.commit('resetBaselineFormCopy');
        this.commit('resetUnderwritingFormCopy');
      }
    },
    resetInputForm(state) {
      state.inputForm = defaultDataHooks.customerInfo.get();
    },
    resetBaselineForm(state, payload) {
      state.contributions.planNames = {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      };
      state.contributions.contribution_structure = "4";
      state.contributions.cols = {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      };
      state.contributions.baseline = {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      };
    },
    resetUnderwritingForm(state) {
      state.underwriting = {
        ...defaultDataHooks.underwriting.get(),
        trend: {
          medical1: 7,
          rx1: 10,
          months: "",
          medical2: 0,
          rx2: 0,
        },
      };
    },
    resetDCForm(state, payload) {
      state.contributions_edit.dc = {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      };
    },
    resetErParcentageForm(state, payload) {
      state.contributions_edit.er_parcentage = {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
          barChartDataSet: [
            {
              label: "Employer",
              backgroundColor: "#29399E",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
            {
              label: "Employee",
              backgroundColor: "#54C8E8",
              data: [0, 0, 0, 0],
              stack: "Stack 0",
            },
          ],
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      };
    },
    resetPopHealthDialYesForm(state, payload) {
      state.calc_yes = defaultDataHooks.popHealthDials.getColumnsWithDials();
      state.pop_health_dial_yes = {
        fully_insured_total_cost: 0,
        self_funded_total_cost: 0,
        additonal_programs_total_cost: 0,
        completedSteps: 0,
        totalSteps: 0,
        q: {
          center_of_excellence_cancer: "No",
          center_of_excellence_surgical: "No",
          high_performing_network: "No",
          patient_assistance_program: "No",
          pharmacy_carve_out: "No",
          reinsurance_carve_out: "No",
          chronic_care_management_diabetes: "No",
          chronic_care_management_hypertension: "No",
          chronic_care_management_msk: "No",
          enhanced_mh_sa_services: "No",
          health_care_navigation: "No",
          maternity_care_management: "No",
          incentivized_plan_design: "No",
          physician_engagement: "No",
        },
        graph_percentage: 0,
      };
    },
    // Edit
    setFormValuesNoFlowEdit(state, payload) {
      state.input_form_edit.type = payload.client_prospect_type;
      state.input_form_edit.medical = payload.medical_rx_pepy;
      state.input_form_edit.effective_date = payload.effective_date;
      state.input_form_edit.claimCostStartDate = payload.claimCostStartDate;
      state.input_form_edit.company_name = payload.company_name;
      state.input_form_edit.funding_type = payload.Funding_type;
      state.input_form_edit.eligible_employees = payload.eligible_employees;
      state.input_form_edit.enrolled_employees = payload.enrolled_employees;
      state.input_form_edit.claims_data_available =
        payload.claims_data_available ? 1 : 0;
      state.input_form_edit.industry = payload.Industry_type;

      state.pop_health_dial_edit.fully_insured_total_cost =
        payload.fully_insured_total_cost;
      state.pop_health_dial_edit.self_funded_total_cost =
        payload.baseline_self_funded_total_cost;
      state.pop_health_dial_edit.additonal_programs_total_cost =
        payload.best_in_breed;
      //state.pop_health_dial_edit.completedSteps= payload;
      //state.pop_health_dial_edit.totalSteps= payload;
      // debugger;
      state.pop_health_dial_edit.q.center_of_excellence_cancer =
        payload.center_of_excellenece_cancer ? "Yes" : "No";
      state.pop_health_dial_edit.q.center_of_excellence_surgical =
        payload.center_of_excellenece_surgical ? "Yes" : "No";
      state.pop_health_dial_edit.q.high_performing_network =
        payload.high_performing_network ? "Yes" : "No";
      state.pop_health_dial_edit.q.patient_assistance_program =
        payload.patient_assistance_program ? "Yes" : "No";
      state.pop_health_dial_edit.q.pharmacy_carve_out =
        payload.pharmacy_carve_out ? "Yes" : "No";
      state.pop_health_dial_edit.q.reinsurance_carve_out =
        payload.reinsurance_carve_out ? "Yes" : "No";
      state.pop_health_dial_edit.q.chronic_care_management_diabetes =
        payload.chronic_care_management_diabetes ? "Yes" : "No";
      state.pop_health_dial_edit.q.chronic_care_management_hypertension =
        payload.chronic_care_management_hypertension ? "Yes" : "No";
      state.pop_health_dial_edit.q.chronic_care_management_msk =
        payload.chronic_care_management_musculoskeletal ? "Yes" : "No";
      state.pop_health_dial_edit.q.enhanced_mh_sa_services =
        payload.enhanced_mental_health_substance_abuse_services ? "Yes" : "No";
      state.pop_health_dial_edit.q.health_care_navigation =
        payload.health_care_navigation ? "Yes" : "No";
      state.pop_health_dial_edit.q.maternity_care_management =
        payload.maternity_care_management ? "Yes" : "No";
      state.pop_health_dial_edit.q.incentivized_plan_design =
        payload.incentivized_plan_design ? "Yes" : "No";
      state.pop_health_dial_edit.q.physician_engagement =
        payload.physician_engagement ? "Yes" : "No";
      state.pop_health_dial_edit.graph_percentage =
        payload.health_outcomes_optimization;
      nonPersistentStore.commit('setIsEditDataLoaded');
    },
    setFormValuesYesFlow(state, {actionType, payload}) {
      storeHooks.validateActionType(actionType, ['edit', 'copy']);
      const inputForm = storeHooks.getInputForm(actionType);
      const contributions = storeHooks.getContributions(actionType);

      nonPersistentStore.commit('setObservationQuestions', payload.obJson.observations);
      inputForm.type = payload.client_prospect_type;
      inputForm.medical = payload.medical_rx_pepy;
      inputForm.effective_date = payload.effective_date;
      inputForm.claimCostStartDate = payload.claimCostStartDate;
      inputForm.company_name = payload.company_name;
      inputForm.funding_type = payload.Funding_type;
      inputForm.eligible_employees = payload.eligible_employees;
      inputForm.enrolled_employees = payload.enrolled_employees;
      inputForm.claims_data_available = payload.claims_data_available ? 1 : 0;
      inputForm.industry = payload.Industry_type;
      inputForm.highCostClaimsThreshold = payload.highCostClaimsThreshold;
      contributions.planNames = payload.cbJson.planNames;
      contributions.contribution_structure = payload.cbJson.contribution_structure;
      contributions.cols = payload.cbJson.cols;
      contributions.baseline = payload.cbJson.baseline;
      storeHooks.setUnderwriting(actionType, payload.uwJson);

      if (actionType === 'copy') {
        nonPersistentStore.commit('setIsCopyDataLoaded');
      }
      if (actionType === 'edit') {
        if (payload.dcJson) {
          state.contributions_edit.dc = payload.dcJson;
          state.contributions_edit.er_parcentage = payload.erJson;
          state.pop_health_dial_yes.fully_insured_total_cost = payload.fully_insured_total_cost;
          state.pop_health_dial_yes.self_funded_total_cost = payload.baseline_self_funded_total_cost;
          state.pop_health_dial_yes.additonal_programs_total_cost = payload.best_in_breed;
          state.pop_health_dial_yes.q.center_of_excellence_cancer = payload.center_of_excellenece_cancer ? "Yes" : "No";
          state.pop_health_dial_yes.q.center_of_excellence_surgical = payload.center_of_excellenece_surgical ? "Yes" : "No";
          state.pop_health_dial_yes.q.high_performing_network = payload.high_performing_network ? "Yes" : "No";
          state.pop_health_dial_yes.q.patient_assistance_program = payload.patient_assistance_program ? "Yes" : "No";
          state.pop_health_dial_yes.q.pharmacy_carve_out = payload.pharmacy_carve_out ? "Yes" : "No";
          state.pop_health_dial_yes.q.reinsurance_carve_out = payload.reinsurance_carve_out ? "Yes" : "No";
          state.pop_health_dial_yes.q.chronic_care_management_diabetes = payload.chronic_care_management_diabetes ? "Yes" : "No";
          state.pop_health_dial_yes.q.chronic_care_management_hypertension = payload.chronic_care_management_hypertension ? "Yes" : "No";
          state.pop_health_dial_yes.q.chronic_care_management_msk = payload.chronic_care_management_musculoskeletal ? "Yes" : "No";
          state.pop_health_dial_yes.q.enhanced_mh_sa_services = payload.enhanced_mental_health_substance_abuse_services ? "Yes" : "No";
          state.pop_health_dial_yes.q.health_care_navigation = payload.health_care_navigation ? "Yes" : "No";
          state.pop_health_dial_yes.q.maternity_care_management = payload.maternity_care_management ? "Yes" : "No";
          state.pop_health_dial_yes.q.incentivized_plan_design = payload.incentivized_plan_design ? "Yes" : "No";
          state.pop_health_dial_yes.q.physician_engagement = payload.physician_engagement ? "Yes" : "No";
          state.pop_health_dial_yes.graph_percentage = payload.health_outcomes_optimization;
        }
        nonPersistentStore.commit('setIsEditDataLoaded');
      }
    },
    setInputFormDataEdit(state, payload) {
      state.input_form_edit = payload;
    },
    setHealthDialDataEdit(state, payload) {
      state.pop_health_dial_edit = payload.pop_health_dial;
      state.calc_edit = payload.calc;
      state.wip_graph_form_edit = payload.wip_graph_form;
    },
    setBaselineDataEdit(state, payload) {
      state.contributions_edit.planNames = payload.planNames;
      state.contributions_edit.cols = payload.cols;
      state.contributions_edit.contribution_structure =
        payload.contributionStructure;
      state.contributions_edit.baseline = payload.baseline;
    },
    setUnderwritingDataEdit(state, payload) {
      state.underwriting_edit = payload;
    },
    resetInputFormEdit(state) {
      state.input_form_edit = defaultDataHooks.customerInfo.get();
    },
    resetPopHealthDialEditForm(state, payload) {
      state.calc_edit = defaultDataHooks.popHealthDials.getColumnsWithDials();
      state.pop_health_dial_edit = {
        fully_insured_total_cost: 0,
        self_funded_total_cost: 0,
        additonal_programs_total_cost: 0,
        completedSteps: 0,
        totalSteps: 0,
        q: {
          center_of_excellence_cancer: "No",
          center_of_excellence_surgical: "No",
          high_performing_network: "No",
          patient_assistance_program: "No",
          pharmacy_carve_out: "No",
          reinsurance_carve_out: "No",
          chronic_care_management_diabetes: "No",
          chronic_care_management_hypertension: "No",
          chronic_care_management_msk: "No",
          enhanced_mh_sa_services: "No",
          health_care_navigation: "No",
          maternity_care_management: "No",
          incentivized_plan_design: "No",
          physician_engagement: "No",
        },
        graph_percentage: 0,
      };
    },
    resetBaselineFormEdit(state, payload) {
      state.contributions_edit.planNames = {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      };
      state.contributions_edit.contribution_structure = "4";
      state.contributions_edit.cols = {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      };
      state.contributions_edit.baseline = {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      };
    },
    resetUnderwritingFormEdit(state) {
      state.underwriting_edit = {
        ...defaultDataHooks.underwriting.get(),
        trend: {
          medical1: 7,
          rx1: 10,
          months: "",
          medical2: 0,
          rx2: 0,
        },
      };
    },
    // Copy
    setFormValuesNoFlowCopy(state, payload) {
      state.input_form_copy.type = payload.client_prospect_type;
      state.input_form_copy.medical = payload.medical_rx_pepy;
      state.input_form_copy.effective_date = payload.effective_date;
      state.input_form_copy.claimCostStartDate = payload.claimCostStartDate;
      state.input_form_copy.company_name = payload.company_name;
      state.input_form_copy.funding_type = payload.Funding_type;
      state.input_form_copy.eligible_employees = payload.eligible_employees;
      state.input_form_copy.enrolled_employees = payload.enrolled_employees;
      state.input_form_copy.claims_data_available =
        payload.claims_data_available ? 1 : 0;
      state.input_form_copy.industry = payload.Industry_type;

      state.pop_health_dial_copy.fully_insured_total_cost =
        payload.fully_insured_total_cost;
      state.pop_health_dial_copy.self_funded_total_cost =
        payload.baseline_self_funded_total_cost;
      state.pop_health_dial_copy.additonal_programs_total_cost =
        payload.best_in_breed;
      //state.pop_health_dial_copy.completedSteps= payload;
      //state.pop_health_dial_copy.totalSteps= payload;
      state.pop_health_dial_copy.q.center_of_excellence_cancer =
        payload.center_of_excellenece_cancer ? "Yes" : "No";
      state.pop_health_dial_copy.q.center_of_excellence_surgical =
        payload.center_of_excellenece_surgical ? "Yes" : "No";
      state.pop_health_dial_copy.q.high_performing_network =
        payload.high_performing_network ? "Yes" : "No";
      state.pop_health_dial_copy.q.patient_assistance_program =
        payload.patient_assistance_program ? "Yes" : "No";
      state.pop_health_dial_copy.q.pharmacy_carve_out =
        payload.pharmacy_carve_out ? "Yes" : "No";
      state.pop_health_dial_copy.q.reinsurance_carve_out =
        payload.reinsurance_carve_out ? "Yes" : "No";
      state.pop_health_dial_copy.q.chronic_care_management_diabetes =
        payload.chronic_care_management_diabetes ? "Yes" : "No";
      state.pop_health_dial_copy.q.chronic_care_management_hypertension =
        payload.chronic_care_management_hypertension ? "Yes" : "No";
      state.pop_health_dial_copy.q.chronic_care_management_msk =
        payload.chronic_care_management_musculoskeletal ? "Yes" : "No";
      state.pop_health_dial_copy.q.enhanced_mh_sa_services =
        payload.enhanced_mental_health_substance_abuse_services ? "Yes" : "No";
      state.pop_health_dial_copy.q.health_care_navigation =
        payload.health_care_navigation ? "Yes" : "No";
      state.pop_health_dial_copy.q.maternity_care_management =
        payload.maternity_care_management ? "Yes" : "No";
      state.pop_health_dial_copy.q.incentivized_plan_design =
        payload.incentivized_plan_design ? "Yes" : "No";
      state.pop_health_dial_copy.q.physician_engagement =
        payload.physician_engagement ? "Yes" : "No";
      state.pop_health_dial_copy.graph_percentage =
        payload.health_outcomes_optimization;
      nonPersistentStore.commit('setIsCopyDataLoaded');
    },
    setInputFormDataCopy(state, payload) {
      state.input_form_copy = payload;
    },
    setHealthDialDataCopy(state, payload) {
      state.pop_health_dial_copy = payload.pop_health_dial;
      state.calc_copy = payload.calc;
      state.wip_graph_form_copy = payload.wip_graph_form;
    },
    setHealthDialDataYesCopy(state, payload) {
      state.pop_health_dial_yes_copy = payload.pop_health_dial;
      state.calc_yes_copy = payload.calc;
      state.wip_graph_form_yes_copy = payload.wip_graph_form;
    },
    setBaselineDataCopy(state, payload) {
      state.contributions_copy.planNames = payload.planNames;
      state.contributions_copy.cols = payload.cols;
      state.contributions_copy.contribution_structure =
        payload.contributionStructure;
      state.contributions_copy.baseline = payload.baseline;
    },
    setUnderwritingDataCopy(state, payload) {
      state.underwriting_copy = payload;
    },
    setDCDataCopy(state, payload) {
      state.contributions_copy.dc = payload;
    },
    setEr_ParcentageDataCopy(state, payload) {
      state.contributions_copy.er_parcentage = payload;
    },
    resetInputFormCopy(state) {
      state.input_form_copy = defaultDataHooks.customerInfo.get();
    },
    resetPopHealthDialCopyForm(state, payload) {
      state.calc_copy = defaultDataHooks.popHealthDials.getColumnsWithDials();
      state.pop_health_dial_copy = {
        fully_insured_total_cost: 0,
        self_funded_total_cost: 0,
        additonal_programs_total_cost: 0,
        completedSteps: 0,
        totalSteps: 0,
        q: {
          center_of_excellence_cancer: "No",
          center_of_excellence_surgical: "No",
          high_performing_network: "No",
          patient_assistance_program: "No",
          pharmacy_carve_out: "No",
          reinsurance_carve_out: "No",
          chronic_care_management_diabetes: "No",
          chronic_care_management_hypertension: "No",
          chronic_care_management_msk: "No",
          enhanced_mh_sa_services: "No",
          health_care_navigation: "No",
          maternity_care_management: "No",
          incentivized_plan_design: "No",
          physician_engagement: "No",
        },
        graph_percentage: 0,
      };
    },
    resetBaselineFormCopy(state, payload) {
      state.contributions_copy.planNames = {
        plan1: "Core",
        plan2: "Buy-Up",
        plan3: "Plan 3",
        plan4: "Plan 4",
        plan5: "Plan 5",
      };
      state.contributions_copy.contribution_structure = "4";
      state.contributions_copy.cols = {
        structure: "4",
        col1: "EE",
        col2: "EE+SP",
        col3: "EE+CH",
        col4: "FAM",
      };
      state.contributions_copy.baseline = {
        plan1: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan2: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan3: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan4: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        plan5: {
          lives: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_lives: 0,
          total: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_total: 0,
          employer: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employer: 0,
          employee: {
            input1: 0,
            input2: 0,
            input3: 0,
            input4: 0,
          },
          sum_employee: 0,
          employer_percentage: {
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
          },
          sum_employer_percentage: 0,
          tier_ratio: {
            ratio1: 0,
            ratio2: 0,
            ratio3: 0,
          },
          plan_design: {
            product_type: "PPO",
            individual_deductible: 0,
            family_deductible: 0,
            co_insurance: 0,
            individual_OOPM: 0,
            family_OOPM: 0,
            physician_visit: 0,
            specialist_visit: 0,
            rx_generic: 0,
            rx_preferred_brand: 0,
            rx_non_preferred_brand: 0,
          },
          total_cost: 0,
          employer_cost: 0,
          employee_cost: 0,
        },
        monthly_cost_lives: 0,
        monthly_cost_total: 0,
        monthly_cost_employer: 0,
        monthly_cost_employee: 0,
        monthly_cost_employer_percentage: 0,
        PEPM_total: 0,
        PEPM_employer: 0,
        PEPM_employee: 0,
        total_contribution: 0,
        employer_portion: 0,
      };
    },
    resetUnderwritingFormCopy(state) {
      state.underwriting_copy = {
        ...defaultDataHooks.underwriting.get(),
        trend: {
          medical1: 7,
          rx1: 10,
          months: "",
          medical2: 0,
          rx2: 0,
        },
      };
    },
  },
  actions: {
    // Auth
    async LogIn({commit}, code) {
      const response = await axios.post(`/login`, {
        code: code,
      });
      return response;
    },

    Logout({commit}) {
      commit("userLogOut");
      localStorage.removeItem("vuex");
      window.location = "/auth/login";
    },

    async GetAdminData({commit}) {
      const response = await axios.get(`/users`, {
        params: {
          user: "",
        },
      });
      if (response.data.status) {
        await commit("setAdminData", response.data.datas);
      }
      return response;
    },

    async GetUsers(_, params) {
      const response = await axios.get(`/users/admin`, {
        params,
      });
      return response;
    },

    async PostRiskMatchData({commit, state}) {
      const response = await axios.get(`/risk-match`, {
        params: {
          // naicsCode: 11,
          naicsCode: state.inputForm.industry,
          productIdFilter: state.inputForm.product,
        },
      });
      if (response.data.success) {
        await commit("setRiskMatchData", response.data);
      }
      return response;
    },
    // List
    async GetFormData({commit}) {
      const response = await axios.get(`/proposal-create-init`);

      if (response.data.status) {
        await commit("setFormData", response.data);
      }
      return response;
    },

    async GetCSVData({commit}, data) {
      const response = await axios.get(`/proposal/export`, {
        params: {
          companyName: data.companyName,
          medicalRxPepy: data.medicalPep,
          eligibleEmployees: data.elgEmpCount,
          effectiveDate: data.effectiveDate,
          fundingType: data.fundinfType,
          startDate: data.dateRange.startDate,
          endDate: data.dateRange.endDate,
          createdBy: data.createdBy,
          enrolledEmployees: data.enrEmpCount,
        },
      });
      return response;
    },

    async GetProgramList({commit}, data) {
      const response = await axios.get(`/proposals`, {
        params: {
          companyName: data.companyName,
          medicalRxPepy: data.medicalPep,
          eligibleEmployees: data.elgEmpCount,
          effectiveDate: data.effectiveDate,
          fundingType: data.fundinfType,
          startDate: data.dateRange.startDate,
          endDate: data.dateRange.endDate,
          createdBy: data.createdBy,
          enrolledEmployees: data.enrEmpCount,
          rowStartFrom: data.rowStartFrom,
          pageNumber: data.pageNumber,
          rowsLimit: data.rowsLimit,
          id: data.formId,
        },
      });
      if (response.data.status) {
        await commit("setProgramList", response.data.datas);
      }
      return response;
    },

    async GetAssumptionProgramData({commit}) {
      const response = await axios.get(`/proposal-create-init`);
      if (response.data.status) {
        await commit("setAssumptionProgramData", {
          assumptions: response.data.assumptions,
          program: response.data.programs,
        });
      }
      return response;
    },

    async ApproveForm({commit}, data) {
      const response = await axios.put(
        `/proposals/${data.formId}/${data.status}`,
        data
      );
      return response;
    },

    // Output
    async GetOutputData({commit}, data) {
      const response = await axios.get(`/proposal/${data.id}/output`, {
        params: {
          formId: data.id,
          type: data.type,
        },
      });
      if (response.data.status) {
        await commit("setOutputData", response.data.datas);
      }
      return response;
    },

    // Create
    async PostNoClaimData({commit, state}, userId) {
      const body = {
        userId: JSON.stringify(userId),
        outputPageUrl: "sample2",
        clientOrProspect: state.inputForm.type,
        companyName: state.inputForm.company_name,
        selectIndustry: state.inputForm.industry,
        medicalRxPEPY: state.inputForm.medical,
        effectiveDate: state.inputForm.effective_date,
        claimCostStartDate: state.inputForm.claimCostStartDate,
        eligibleEmployees: state.inputForm.eligible_employees,
        enrolledEmployees: state.inputForm.enrolled_employees,
        fundingType: state.inputForm.funding_type,
        claimsDataAvailable: state.inputForm.claims_data_available !== 0,
        careNavigation: state.pop_health_dial.q.health_care_navigation === "No" ? 0 : 1,
        diabetes: state.pop_health_dial.q.chronic_care_management_diabetes === "No" ? 0 : 1,
        hypertension: state.pop_health_dial.q.chronic_care_management_hypertension === "No" ? 0 : 1,
        womensHealth: state.pop_health_dial.q.maternity_care_management === "No" ? 0 : 1,
        mentalHealth: state.pop_health_dial.q.enhanced_mh_sa_services === "No" ? 0 : 1,
        musculoskeletal: state.pop_health_dial.q.chronic_care_management_msk === "No" ? 0 : 1,
        cancerCenters: state.pop_health_dial.q.center_of_excellence_cancer === "No" ? 0 : 1,
        surgicalCenters: state.pop_health_dial.q.center_of_excellence_surgical === "No" ? 0 : 1,
        highPerformingNetwork: state.pop_health_dial.q.high_performing_network === "No" ? 0 : 1,
        incentivizedPlanDesign: state.pop_health_dial.q.incentivized_plan_design === "No" ? 0 : 1,
        physicianEngagement: state.pop_health_dial.q.physician_engagement === "No" ? 0 : 1,
        pharmacyCarveOut: state.pop_health_dial.q.pharmacy_carve_out === "No" ? 0 : 1,
        patientAssistanceProgram: state.pop_health_dial.q.patient_assistance_program === "No" ? 0 : 1,
        reinsuranceCarveOut: state.pop_health_dial.q.reinsurance_carve_out === "No" ? 0 : 1,
        fullyInsuredTotalCost: state.pop_health_dial.fully_insured_total_cost,
        baselineSelfFundedTotalCost: state.pop_health_dial.self_funded_total_cost,
        bestInBreed: state.pop_health_dial.additonal_programs_total_cost,
        healthOutcomesOptimization: state.pop_health_dial.graph_percentage,
        baseline: state.wip_graph_form.pepy.baselineData,
        costMitigationStrategy: state.wip_graph_form.pepy.costMitigationStrategyData,
        baselineClaims: state.wip_graph_form.spend.baselineClaims,
        baselineClaimsCosts: state.wip_graph_form.spend.baselineFixedCost,
        costMitigationStrategyClaims: state.wip_graph_form.spend.costMitigationStrategyClaims,
        costMitigationStrategyFixedCosts: state.wip_graph_form.spend.costMitigationStrategyFixedCosts,

        // these 2 are use in the output
        baseLineTrend: state.wip_graph_form.compositeBaselineTrend,
        costMitigationTrend: state.wip_graph_form.compositeBestInClass,

        compositeBestInClass: state.wip_graph_form.compositeBestInClass,
        compositeBaselineTrend: state.wip_graph_form.compositeBaselineTrend,

        totalCost: state.wip_graph_form.mitigation_cost,
      };
      // debugger;
      const response = await axios.post(`/proposals`, body);
      if (response.data.status) {
        await commit("resetInputForm");
        await commit("resetPopHealthDialForm");
      }
      return response;
    },


    async GetFormValuesEdit({commit}, data) {
      const params = {
        formId: data.id,
        type: data.flow,
      }
      const response = await axios.get(`/proposal/${data.id}`, {params});
      return response;
    },

    // Edit
    async EditNoClaimData({commit, state}, data) {
      const body = {
        userId: JSON.stringify(data.userId),
        formId: data.formId,
        outputPageUrl: "sample2",
        clientOrProspect: state.input_form_edit.type,
        companyName: state.input_form_edit.company_name,
        selectIndustry: state.input_form_edit.industry,
        medicalRxPEPY: state.input_form_edit.medical,
        effectiveDate: state.input_form_edit.effective_date,
        claimCostStartDate: state.input_form_edit.claimCostStartDate,
        eligibleEmployees: state.input_form_edit.eligible_employees,
        enrolledEmployees: state.input_form_edit.enrolled_employees,
        fundingType: state.input_form_edit.funding_type,
        claimsDataAvailable: state.input_form_edit.claims_data_available !== 0,
        careNavigation: state.pop_health_dial_edit.q.health_care_navigation == "No" ? 0 : 1,
        diabetes: state.pop_health_dial_edit.q.chronic_care_management_diabetes == "No" ? 0 : 1,
        hypertension: state.pop_health_dial_edit.q.chronic_care_management_hypertension == "No" ? 0 : 1,
        womensHealth: state.pop_health_dial_edit.q.maternity_care_management == "No" ? 0 : 1,
        mentalHealth: state.pop_health_dial_edit.q.enhanced_mh_sa_services == "No" ? 0 : 1,
        musculoskeletal: state.pop_health_dial_edit.q.chronic_care_management_msk == "No" ? 0 : 1,
        cancerCenters: state.pop_health_dial_edit.q.center_of_excellence_cancer == "No" ? 0 : 1,
        surgicalCenters: state.pop_health_dial_edit.q.center_of_excellence_surgical == "No" ? 0 : 1,
        highPerformingNetwork: state.pop_health_dial_edit.q.high_performing_network == "No" ? 0 : 1,
        incentivizedPlanDesign: state.pop_health_dial_edit.q.incentivized_plan_design == "No" ? 0 : 1,
        physicianEngagement: state.pop_health_dial_edit.q.physician_engagement == "No" ? 0 : 1,
        pharmacyCarveOut: state.pop_health_dial_edit.q.pharmacy_carve_out == "No" ? 0 : 1,
        patientAssistanceProgram: state.pop_health_dial_edit.q.patient_assistance_program == "No" ? 0 : 1,
        reinsuranceCarveOut: state.pop_health_dial_edit.q.reinsurance_carve_out == "No" ? 0 : 1,
        fullyInsuredTotalCost: state.pop_health_dial_edit.fully_insured_total_cost,
        baselineSelfFundedTotalCost: state.pop_health_dial_edit.self_funded_total_cost,
        bestInBreed: state.pop_health_dial_edit.additonal_programs_total_cost,
        healthOutcomesOptimization: state.pop_health_dial_edit.graph_percentage,
        baseline: state.wip_graph_form_edit.pepy.baselineData,
        costMitigationStrategy: state.wip_graph_form_edit.pepy.costMitigationStrategyData,
        baselineClaims: state.wip_graph_form_edit.spend.baselineClaims,
        baselineClaimsCosts: state.wip_graph_form_edit.spend.baselineFixedCost,
        costMitigationStrategyClaims: state.wip_graph_form_edit.spend.costMitigationStrategyClaims,
        costMitigationStrategyFixedCosts: state.wip_graph_form_edit.spend.costMitigationStrategyFixedCosts,

        baseLineTrend: state.wip_graph_form_edit.compositeBaselineTrend,
        costMitigationTrend: state.wip_graph_form_edit.compositeBestInClass,

        compositeBestInClass: state.wip_graph_form.compositeBestInClass,
        compositeBaselineTrend: state.wip_graph_form.compositeBaselineTrend,

        totalCost: state.wip_graph_form_edit.mitigation_cost,
      };
      // return;
      const response = await axios.put(`/proposals/${data.formId}`, body);
      if (response.data.status) {
        await commit("resetInputFormEdit");
        await commit("resetPopHealthDialEditForm");
      }
      return response;
    },
    async SubmitYesClaimPostApproval({commit, state}, data) {
      const body = calculateDataHooks.proposal.getYesClaimDataFull(state, data, 'edit');
      const response = await axios.post(`/proposals`, body);
      if (response.data.status) {
        await commit("resetInputFormEdit");
        await commit("resetDCForm");
        await commit("resetErParcentageForm");
        await commit("resetPopHealthDialYesForm");
      }
      return response;
    },

    // Copy
    async InsertNoClaimCopy({commit, state}, data) {
      const body = {
        userId: JSON.stringify(data.userId),
        formId: data.formId,
        outputPageUrl: "sample2",
        clientOrProspect: state.input_form_copy.type,
        companyName: state.input_form_copy.company_name,
        selectIndustry: state.input_form_copy.industry,
        medicalRxPEPY: state.input_form_copy.medical,
        effectiveDate: state.input_form_copy.effective_date,
        eligibleEmployees: state.input_form_copy.eligible_employees,
        enrolledEmployees: state.input_form_copy.enrolled_employees,
        fundingType: state.input_form_copy.funding_type,
        claimsDataAvailable: state.input_form_copy.claims_data_available == 0 ? false : true,
        careNavigation: state.pop_health_dial_copy.q.health_care_navigation == "No" ? 0 : 1,
        diabetes: state.pop_health_dial_copy.q.chronic_care_management_diabetes == "No" ? 0 : 1,
        hypertension: state.pop_health_dial_copy.q.chronic_care_management_hypertension == "No" ? 0 : 1,
        womensHealth: state.pop_health_dial_copy.q.maternity_care_management == "No" ? 0 : 1,
        mentalHealth: state.pop_health_dial_copy.q.enhanced_mh_sa_services == "No" ? 0 : 1,
        musculoskeletal: state.pop_health_dial_copy.q.chronic_care_management_msk == "No" ? 0 : 1,
        cancerCenters: state.pop_health_dial_copy.q.center_of_excellence_cancer == "No" ? 0 : 1,
        surgicalCenters: state.pop_health_dial_copy.q.center_of_excellence_surgical == "No" ? 0 : 1,
        highPerformingNetwork: state.pop_health_dial_copy.q.high_performing_network == "No" ? 0 : 1,
        incentivizedPlanDesign: state.pop_health_dial_copy.q.incentivized_plan_design == "No" ? 0 : 1,
        physicianEngagement: state.pop_health_dial_copy.q.physician_engagement == "No" ? 0 : 1,
        pharmacyCarveOut: state.pop_health_dial_copy.q.pharmacy_carve_out == "No" ? 0 : 1,
        patientAssistanceProgram: state.pop_health_dial_copy.q.patient_assistance_program == "No" ? 0 : 1,
        reinsuranceCarveOut: state.pop_health_dial_copy.q.reinsurance_carve_out == "No" ? 0 : 1,
        fullyInsuredTotalCost: state.pop_health_dial_copy.fully_insured_total_cost,
        baselineSelfFundedTotalCost: state.pop_health_dial_copy.self_funded_total_cost,
        bestInBreed: state.pop_health_dial_copy.additonal_programs_total_cost,
        healthOutcomesOptimization: state.pop_health_dial_copy.graph_percentage,
        baseline: state.wip_graph_form_copy.pepy.baselineData,
        costMitigationStrategy: state.wip_graph_form_copy.pepy.costMitigationStrategyData,
        baselineClaims: state.wip_graph_form_copy.spend.baselineClaims,
        baselineClaimsCosts: state.wip_graph_form_copy.spend.baselineFixedCost,

        costMitigationStrategyClaims: state.wip_graph_form_copy.spend.costMitigationStrategyClaims,
        costMitigationStrategyFixedCosts: state.wip_graph_form_copy.spend.costMitigationStrategyFixedCosts,

        baseLineTrend: state.wip_graph_form_copy.compositeBaselineTrend,
        costMitigationTrend: state.wip_graph_form_copy.compositeBestInClass,

        totalCost: state.wip_graph_form_copy.mitigation_cost,
      };
      // debugger;
      const response = await axios.post(`/proposals/${data.formId}/version`, body);
      if (response.data.status) {
        await commit("resetInputFormCopy");
        await commit("resetPopHealthDialCopyForm");
      }
      return response;
    },
  },

  plugins: [vuexLocal.plugin],
});
