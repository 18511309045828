// hooks
import {helperHooks} from '@/hooks/helper.hooks';

export const popHealthDials = {
  getDials() {
    return helperHooks.deepCopy({
      self_funded: 0,
      center_of_excellence_cancer: 0,
      center_of_excellence_surgical: 0,
      high_performing_network: 0,
      patient_assistance_program: 0,
      pharmacy_carve_out: 0,
      reinsurance_carve_out: 0,
      case_management_carve_out: 0,
      chronic_care_management_diabetes: 0,
      chronic_care_management_hypertension: 0,
      chronic_care_management_msk: 0,
      digital_wearable: 0,
      enhanced_mh_sa_services: 0,
      fertility_services: 0,
      health_care_navigation: 0,
      maternity_care_management: 0,
      physician_engagement: 0,
      transparency_tools: 0,
      incentivized_plan_design: 0,
      total: 0,
    });
  },
  getColumnsWithDials() {
    return helperHooks.deepCopy({
      g: this.getDials(),
      h: this.getDials(),
      i: this.getDials(),
      j: this.getDials(),
      k: this.getDials(),
      m: this.getDials(),
      n: this.getDials(),
      o: this.getDials(),
      p: this.getDials(),
      q: this.getDials(),
      s: this.getDials(),
      t: this.getDials(),
      u: this.getDials(),
      v: this.getDials(),
      w: this.getDials(),
    });
  },
 
  pop_health_dial() {
    return helperHooks.deepCopy({
      fully_insured_total_cost: 0,
      self_funded_total_cost: 0,
      additonal_programs_total_cost: 0,
      completedSteps: 0,
      totalSteps: 0,
      q: {
        center_of_excellence_cancer: 'No',
        center_of_excellence_surgical: 'No',
        high_performing_network: 'No',
        patient_assistance_program: 'No',
        pharmacy_carve_out: 'No',
        reinsurance_carve_out: 'No',
        chronic_care_management_diabetes: 'No',
        chronic_care_management_hypertension: 'No',
        chronic_care_management_msk: 'No',
        enhanced_mh_sa_services: 'No',
        health_care_navigation: 'No',
        maternity_care_management: 'No',
        incentivized_plan_design: 'No',
        physician_engagement: 'No',
      },
      graph_percentage: 0,
    });
  },
};
