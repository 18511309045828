import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import Toasted from "vue-toasted";
import {extend, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import axios from "@/services/axios";

extend("required", {
  ...required,
  message: "This field is required.",
});

extend("nonZero", {
  validate(value) {
    return {
      required: true,
      valid: value != 0,
    };
  },
  computesRequired: true,
  message: "This field is required.",
});

extend("nonMissingDate", {
  validate(value) {
    return {
      required: true,
      valid: value !== 'mm/dd/yyyy',
    };
  },
  computesRequired: true,
  message: "This field is required.",
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);

// Interceptor

axios.interceptors.request.use(
  (config) => {
    const token = store.state.user.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response.status === 401) {
      // store.commit('userLogOut');
      store.replaceState({});
      router.push("/auth/login");
    }
    return Promise.reject(error);
  }
);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Toast
Vue.use(Toasted, {
  duration: 2000,
  keepOnHover: true,
  action: {
    text: "Cancel",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});

Vue.config.productionTip = false;

// filter

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    hideNegligibleDecimalDigitsOnFocus: true,
    autoDecimalDigits: false,
    useGrouping: true,
    accountingSign: false,
  });
  return formatter.format(value);
});
Vue.filter("toCurrency1", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    hideNegligibleDecimalDigitsOnFocus: true,
    autoDecimalDigits: false,
    useGrouping: true,
    precision: 2,
    accountingSign: false,
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
