// hooks
import { helperHooks } from '@/hooks/helper.hooks';

export const underwriting = {
  fixLossRatio(underwriting) {
    if (!underwriting.use_assumptions_for_claims) {
      underwriting.use_assumptions_for_claims = this.get().use_assumptions_for_claims;
    }
    if (!underwriting.claims_as_a_percentage_of_total) {
      underwriting.claims_as_a_percentage_of_total = this.get().claims_as_a_percentage_of_total;
    }
    if (!underwriting.medical_percentage_of_claims) {
      underwriting.medical_percentage_of_claims = this.get().medical_percentage_of_claims;
    }
  },
  get() {
    return helperHooks.deepCopy({
      // loss ratio
      use_assumptions_for_claims: false,
      claims_as_a_percentage_of_total: 70,
      medical_percentage_of_claims: 80,

      selectedMonth: "Month",
      selectedYear: "Year",
      date: {
        date1: "",
        date2: "",
        date4: "",
        date3: "",
        date5: "",
        date6: "",
        date7: "",
        date8: "",
        date9: "",
        date10: "",
        date11: "",
        date12: "",
      },
      medical_claims: {
        claim1: 0,
        claim2: 0,
        claim3: 0,
        claim4: 0,
        claim5: 0,
        claim6: 0,
        claim7: 0,
        claim8: 0,
        claim9: 0,
        claim10: 0,
        claim11: 0,
        claim12: 0,
      },
      rx_claims: {
        claim1: 0,
        claim2: 0,
        claim3: 0,
        claim4: 0,
        claim5: 0,
        claim6: 0,
        claim7: 0,
        claim8: 0,
        claim9: 0,
        claim10: 0,
        claim11: 0,
        claim12: 0,
      },
      fixed: {
        fixed1: 0,
        fixed2: 0,
        fixed3: 0,
        fixed4: 0,
        fixed5: 0,
        fixed6: 0,
        fixed7: 0,
        fixed8: 0,
        fixed9: 0,
        fixed10: 0,
        fixed11: 0,
        fixed12: 0,
      },
      employees: {
        employee1: 0,
        employee2: 0,
        employee3: 0,
        employee4: 0,
        employee5: 0,
        employee6: 0,
        employee7: 0,
        employee8: 0,
        employee9: 0,
        employee10: 0,
        employee11: 0,
        employee12: 0,
      },
      medical_claims_total: 0,
      rx_claims_total: 0,
      fixed_total: 0,
      employees_total: 0,
      medical_claims_PEPM: 0,
      rx_claims_PEPM: 0,
      fixed_PEPM: 0,
      high_cost_claims: {
        medical_claims: 0,
        rx_claims: 0,
      },
      net_claims_PEPM: {
        medical_claims: 0,
        rx_claims: 0,
        fixed: 0,
      },
      trend: {
        medical1: 0,
        rx1: 0,
        months: "",
        medical2: 0,
        rx2: 0,
      },
      projected_claims: 0,
      admin_fees: 0,
      stop_loss_premiums: 0,
      projected_Y1_updated_PEPM: 0,
      projected_Y1_baseline_PEPM: 0,
      estimated_updated_vs_baseline: 0,
    });
  },
};
