export class AssumptionsService {

  static getCalculatedLossRatio(enrolledEmployeesAmount) {

    const lossRatioMin_A3 = .75
    const lossRatioMax_A4 = .90

    const enrollmentMin_B3 = 100
    const enrollmentMax_B4 = 1000

    const E3 = enrolledEmployeesAmount

// =MAX(MIN(((E3-B3)*A4+(B4-E3)*A3)/(B4-B3),A4),A3)

    const diff1 = (E3 - enrollmentMin_B3) * lossRatioMax_A4
    const diff2 = (enrollmentMax_B4 - E3) * lossRatioMin_A3
    const enrollmentDelta = enrollmentMax_B4 - enrollmentMin_B3
    const result = (diff1 + diff2) / enrollmentDelta
    const minRL = Math.min(result, lossRatioMax_A4)
    const maxRL = Math.max(minRL, lossRatioMin_A3)
// =MAX(MIN((diff1+diff2)/enrollmentDelta,A4),A3)
// =MAX(MIN(result,A4),A3)
// =MAX(minRL,A3)


    // console.log(diff1)
    // console.log(diff2)
    // console.log(maxRL)

    return maxRL;
  }

}
