import {PopHealthService} from "@/services/domain/popHealth.service";
import {AssumptionsService} from "@/services/domain/assumptions.service";
import {ExcelService} from "@/services/excel.service";
import {helperHooks} from "@/hooks/helper.hooks";

export class WipService {

  #inputForm
  #graphForm
  M2
  N3


  popHealthDialsB1 = 0
  assumptionsE4 = 0
  E46 = 0
  E47 = 0
  H46 = 0
  H47 = 0
  I48 = 0
  I49 = 0
  K46 = 0
  N46 = 0
  Q46 = 0
  T46 = 0
  K47 = 0
  N47 = 0
  Q47 = 0
  T47 = 0
  L48 = 0
  O48 = 0
  R48 = 0
  U48 = 0
  L49 = 0
  O49 = 0
  R49 = 0
  U49 = 0


  constructor(inputForm, graphForm, N3) {
    this.#inputForm = inputForm
    this.#graphForm = graphForm

    const {funding_type} = this.#inputForm
    const {cost_mitigation_trend} = this.#graphForm
    this.M2 = WipService.GetM2(funding_type, cost_mitigation_trend)

    this.N3 = N3

    this.popHealthDialsB1 = PopHealthService.getFullyInsuredTotalCost(inputForm)
    this.assumptionsE4 = AssumptionsService.getCalculatedLossRatio(inputForm.enrolled_employees)

  }

  static InitFormData(usePreloadValues = false) {
    const set = {
      baseline_trend: usePreloadValues ? 0.085 : 0,
      cost_mitigation_trend: usePreloadValues ? 0.075 : 0,
      baseline_trend_perc: usePreloadValues ? 8.5 : 0,
      cost_mitigation_trend_perc: usePreloadValues ? 7.5 : 0,
      mitigation_cost: 0,
      compositeBestInClass: 0, //R2
      compositeBaselineTrend: 0, //R3
      pepy: {
        baselineData: [],
        costMitigationStrategyData: [],
      },
      spend: {
        baselineClaims: [],
        baselineFixedCost: [],
        costMitigationStrategyClaims: [],
        costMitigationStrategyFixedCosts: [],
      }
    }

    return helperHooks.deepCopy(set);
  }

  static GetM2(funding_type, cost_mitigation_trend) {
    return cost_mitigation_trend + (funding_type === 3 ? 0 : 0.01)
  }

  GetE46() {
    // 	E46 =ROUND('Pop Health Dials'!$B$1*Assumptions!$E$4,-3)
    return this.E46 = ExcelService.RoundLeft(this.popHealthDialsB1 * this.assumptionsE4)


  }

  GetE47() {
    // =ROUND('Pop Health Dials'!$B$1*(1-Assumptions!$E$4),-3)
    return this.E47 = ExcelService.RoundLeft(this.popHealthDialsB1 * (1 - this.assumptionsE4))
  }


  GetH46() {
    // 	=ROUND(E46*(1+$M$2),-3)
    return this.H46 = ExcelService.RoundLeft(this.E46 * (1 + this.M2))

  }


  GetH47() {
    // 	=ROUND(E47*(1+$M$2),-3)
    return this.H47 = ExcelService.RoundLeft(this.E47 * (1 + this.M2))

  }

  GetI48(popHealthDialsG19) {
    // 	=ROUND(($F48-'Pop Health Dials'!G19)*(1+$M$2),-3)
    // F8 = E46
    return this.I48 = ExcelService.RoundLeft((this.E46 - popHealthDialsG19) * (1 + this.M2))

  }


  GetK46() {
    // 	=ROUND(H46*(1+$N$2),-3)
    return this.K46 = ExcelService.RoundLeft(this.H46 * (1 + this.M2))

  }

  GetN46() {
    // =ROUND(K46*(1+$O$2),-3)
    // Q2=M2
    return this.N46 = ExcelService.RoundLeft(this.K46 * (1 + this.M2))


  }

  GetQ46() {
    //=ROUND(N46*(1+$P$2),-3)
    // P2=M2
    return this.Q46 = ExcelService.RoundLeft(this.N46 * (1 + this.M2))

  }

  GetT46() {
    //=ROUND(Q46*(1+$Q$2),-3)
    // Q2=M2
    return this.T46 = ExcelService.RoundLeft(this.Q46 * (1 + this.M2))

  }

  GetK47() {
    // =ROUND(H47*(1+$N$2),-3)
    return this.K47 = ExcelService.RoundLeft(this.H47 * (1 + this.M2))

  }

  GetN47() {
    //=ROUND(K47*(1+$O$2),-3)
    return this.N47 = ExcelService.RoundLeft(this.K47 * (1 + this.M2))

  }

  GetQ47() {
    //=ROUND(N47*(1+$P$2),-3)
    return this.Q47 = ExcelService.RoundLeft(this.N47 * (1 + this.M2))

  }

  GetT47() {
    //=ROUND(Q47*(1+$Q$2),-3)
    return this.T47 = ExcelService.RoundLeft(this.Q47 * (1 + this.M2))

  }


  GetL48(popHealthDialsH19) {
    // 	=ROUND(($F48-'Pop Health Dials'!H19)*(1+$M$2)*(1+$N$3),-3)
    // F8 = E46
    return this.L48 = ExcelService.RoundLeft((this.E46 - popHealthDialsH19) * (1 + this.M2) * (1 + this.N3))

  }

  GetO48(popHealthDialsI19) {
    // =ROUND(($F48-'Pop Health Dials'!I19)*(1+$M$2)*(1+$N$3)*(1+$O$3),-3)
    // O3 = this.N3
    // F8 = E46
    return this.O48 = ExcelService.RoundLeft((this.E46 - popHealthDialsI19) * (1 + this.M2) * Math.pow((1 + this.N3), 2))

  }

  GetR48(popHealthDialsJ19) {
    // =ROUND(($F48-'Pop Health Dials'!J19)*(1+$M$2)*(1+$N$3)*(1+$O$3)*(1+$P$3),-3)
    // O3 = this.N3
    // F8 = E46
    return this.R48 = ExcelService.RoundLeft((this.E46 - popHealthDialsJ19) * (1 + this.M2) * Math.pow((1 + this.N3), 3))

  }

  GetU48(popHealthDialsK19) {
    // =ROUND(($F48-'Pop Health Dials'!K19)*(1+$M$2)*(1+$N$3)*(1+$O$3)*(1+$P$3)*(1+$Q$3),-3)
    // O3 = this.N3
    // F8 = E46
    console.log(`popHealthDialsK19 => ${popHealthDialsK19}`)
    console.table({f8: this.E46, popHealthDialsK19, m2: (1 + this.M2), n3: Math.pow((1 + this.N3), 4)})
    return this.U48 = ExcelService.RoundLeft((this.E46 - popHealthDialsK19) * (1 + this.M2) * Math.pow((1 + this.N3), 4))

  }


  GetI49(popHealthDialsM19) {
    // =ROUND(($F49+'Pop Health Dials'!M19)*(1+$M$2),-3)
    // F9 = E47
    return this.I49 = ExcelService.RoundLeft((this.E47 + popHealthDialsM19) * (1 + this.M2))

  }

  GetL49(popHealthDialsN19) {
    //  =ROUND(($F49+'Pop Health Dials'!N19)*(1+$M$2)*(1+$N$3),-3)
    // F9= E47
    return this.L49 = ExcelService.RoundLeft((this.E47 + popHealthDialsN19) * (1 + this.M2) * (1 + this.N3))

  }

  GetO49(popHealthDialsO19) {
    // =ROUND(($F49+'Pop Health Dials'!O19)*(1+$M$2)*(1+$N$3)*(1+$O$3),-3)
    // F9= E47
    return this.O49 = ExcelService.RoundLeft((this.E47 + popHealthDialsO19) * (1 + this.M2) * Math.pow((1 + this.N3), 2))

  }

  GetR49(popHealthDialsP19) {
    //=ROUND(($F49+'Pop Health Dials'!P19)*(1+$M$2)*(1+$N$3)*(1+$O$3)*(1+$P$3),-3)
    // F9= E47
    return this.R49 = ExcelService.RoundLeft((this.E47 + popHealthDialsP19) * (1 + this.M2) * Math.pow((1 + this.N3), 3))

  }

  GetU49(popHealthDialsQ19) {
    //=ROUND(($F49+'Pop Health Dials'!Q19)*(1+$M$2)*(1+$N$3)*(1+$O$3)*(1+$P$3)*(1+$Q$3),-3)
    // F9= E47
    return this.U49 = ExcelService.RoundLeft((this.E47 + popHealthDialsQ19) * (1 + this.M2) * Math.pow((1 + this.N3), 4))

  }


}
