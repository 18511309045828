import {ProposalService} from "@/services/domain/proposal.service";
import {ExcelService} from "@/services/excel.service";

export class PopHealthService {

  static getFullyInsuredTotalCost(form) {

    // excel
    // B1 = ROUND(Input!B9*Input!B19*(1+7.5%+IF(Input!$B$21="Self-Funded",0,1%))^((Input!B15-Input!B7-365)/365),-3)

    const {medical, enrolled_employees, funding_type, effective_date, claimCostStartDate} = form
    const M2 = .075 // value is fixed on formula
    const M2_Ext = (1 + M2)
    const part1 = medical * enrolled_employees
    const part2 = (M2_Ext + (funding_type === 3 ? 0 : 0.01))
    const part3 = ProposalService.getClaimStartToEffectiveDateDiff(claimCostStartDate, effective_date)
    const full = part1 * Math.pow(part2, part3)

    return ExcelService.RoundLeft(+full, 3)

  }


  static setAllToZero(vm, key) {
    vm.g[key] = 0;
    vm.h[key] = 0;
    vm.i[key] = 0;
    vm.j[key] = 0;
    vm.k[key] = 0;
    vm.m[key] = 0;
    vm.n[key] = 0;
    vm.o[key] = 0;
    vm.p[key] = 0;
    vm.q[key] = 0;
    vm.s[key] = 0;
    vm.t[key] = 0;
    vm.u[key] = 0;
    vm.v[key] = 0;
    vm.w[key] = 0;
  }


  static haveSimpleCharacterH(dialName) {
    if (dialName === 'center_of_excellence_surgical') return false;
    if (dialName === 'reinsurance_carve_out') return false;
    if (dialName === 'chronic_care_management_diabetes') return false;
    if (dialName === 'health_care_navigation') return false;
    if (dialName === 'physician_engagement') return false;
    return true;
  }

  static getCharacterValueM(dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement) {
    if (dialName === 'patient_assistance_program') {
      console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
      return savingsY1 * fixedY1 * fullyInsuredTotalCost;
    }
    if (dialName === 'reinsurance_carve_out') {
      console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
      return -fixedY1 * fullyInsuredTotalCost;
    }

    // =IF($B13="Yes",Programs!$D20*Input!$B$19*Programs!$F20,0)
    if (dialName === 'chronic_care_management_msk') {
      console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
      return fixedY1 * enrolledEmployees * engagement;
    }

    // M17: Physical Engagement
    //=IF($B17="Yes",Programs!$D29*Input!$B$19,0)
    if (dialName === 'physician_engagement') {
      console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
      return fixedY1 * enrolledEmployees;
    }

    // M18: Incentive Plan Design
    //=IF($B18="Yes",Programs!$D32*12*Input!$B$19,0)
    if (dialName === 'incentivized_plan_design') {
      console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
      return fixedY1 * 12 * enrolledEmployees;
    }

    // Others

    let constant_1 = 12


    console.table({dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement})
    return fixedY1 * (constant_1 || 1) * (enrolledEmployees | 1) * (prevalence || 1) * (engagement || 1);
  }

  static getCharacterValueN(valueM, fixedY2, enrolledEmployees, dialName, fullyInsuredTotalCost) {
    if (fixedY2) return fixedY2 * 12 * enrolledEmployees;
    if (dialName === 'reinsurance_carve_out') return -fixedY2 * fullyInsuredTotalCost;
    return valueM;
  }

  static getCharacterValueO(valueN, fixedY3, enrolledEmployees) {
    if (fixedY3) return fixedY3 * 12 * enrolledEmployees;
    return valueN;
  }

  static getCharacterValueG(valueM, dialName, savingsY1, fullyInsuredTotalCost) {
    if (dialName === 'reinsurance_carve_out') return 0;
    return savingsY1 * fullyInsuredTotalCost + valueM;
  }

  static getCharacterValueH(valueN, valueG, valueI, dialName, savingsY2, fullyInsuredTotalCost) {
    if (dialName === 'reinsurance_carve_out') return 0;
    if (dialName === 'center_of_excellence_surgical') return (valueG + valueI) / 2;
    if (PopHealthService.haveSimpleCharacterH(dialName)) return valueG;
    return savingsY2 * fullyInsuredTotalCost + valueN;
  }

  static getCharacterValueI(valueO, valueH, dialName, savingsY3, fullyInsuredTotalCost) {
    if (dialName === 'reinsurance_carve_out') return 0;
    if (savingsY3) return savingsY3 * fullyInsuredTotalCost + valueO;
    return valueH;
  }

  static getCharacterValueJ(valueI, dialName) {
    if (dialName === 'reinsurance_carve_out') return 0;
    return valueI;
  }

  static getCharacterValueK(valueI, dialName) {
    if (dialName === 'reinsurance_carve_out') return 0;
    return valueI;
  }


}
