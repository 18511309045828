export class ExcelService {


  static RoundLeft(number, digit = 3) {
    const num_digit = Math.pow(10, digit);
    return Math.round(number / num_digit) * num_digit;
  }


}
