import Vue from 'vue';
import Vuex from 'vuex';
// hooks
import { helperHooks } from '@/hooks/helper.hooks';

Vue.use(Vuex);

const observationQuestionsDefault = [
  { id: 1, value: 1 },
  { id: 2, value: 1 },
  { id: 3, value: 1 },
  { id: 4, value: 1 },
  { id: 5, value: 1 },
  { id: 6, value: 1 },
  { id: 7, value: 1 },
  { id: 8, value: 1 },
  { id: 9, value: 1 },
  { id: 10, value: 1 },
];
const authDataDefault = {
  role: '',
  token: '',
  refToken: '',
  userId: 0,
  userName: '',
  email: '',
};

export const nonPersistentStore = new Vuex.Store({
  namespaced: true,
  state: {
    isEditDataLoaded: false,
    isCopyDataLoaded: false,
    observationQuestions: helperHooks.deepCopy(observationQuestionsDefault),
    authData: helperHooks.deepCopy(authDataDefault),
  },
  mutations: {
    setObservationQuestions(state, observations) {
      if (observations) {
        state.observationQuestions = helperHooks.deepCopy(observations);
      } else {
        state.observationQuestions = helperHooks.deepCopy(observationQuestionsDefault);
      }
    },
    setAuthData(state, authData) {
      state.authData = authData;
    },
    setIsEditDataLoaded(state) {
      state.isEditDataLoaded = true;
    },
    setIsEditDataNotLoaded(state) {
      state.isEditDataLoaded = false;
    },
    setIsCopyDataLoaded(state) {
      state.isCopyDataLoaded = true;
    },
    setIsCopyDataNotLoaded(state) {
      state.isCopyDataLoaded = false;
    },
  },
});
