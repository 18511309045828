import {TimeService} from "@/services/time.service";

export class ProposalService {
  static getClaimStartToEffectiveDateDiff = (claimCostStartDate, effective_date) => {
    const diff = TimeService.GetInterval(claimCostStartDate, effective_date)
    const product = ((diff - 365) / 365)
    console.log(`Dates diff => ${diff}`)
    console.log(`product => ${product}`)

    // adding 1 to match excel dates diff product
    return product
  }

}
