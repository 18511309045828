// store
import store from '@/store';
// hooks
import {storeHooks} from '@/hooks/store.hooks';

export const proposal = {
  getCustomerInfoData(actionType) {
    let inputForm = storeHooks.getInputForm(actionType);
    return {
      clientOrProspect: inputForm.type,
      companyName: inputForm.company_name,
      selectIndustry: inputForm.industry,
      medicalRxPEPY: inputForm.medical,
      effectiveDate: inputForm.effective_date,
      claimCostStartDate: inputForm.claimCostStartDate,
      eligibleEmployees: inputForm.eligible_employees,
      enrolledEmployees: inputForm.enrolled_employees,
      fundingType: inputForm.funding_type,
      claimsDataAvailable: !!inputForm.claims_data_available,
      highCostClaimsThreshold: inputForm.highCostClaimsThreshold,
    };
  },
  getYesClaimData(actionType, formId) {
    let contributions = storeHooks.getContributions(actionType);
    let underwriting = storeHooks.getUnderwriting(actionType);
    const body = {
      ...this.getCustomerInfoData(actionType),
      approvalStatus: 1,
      outputPageUrl: 'sample2',
      cbJson: {
        planNames: contributions.planNames,
        contribution_structure: contributions.contribution_structure,
        cols: contributions.cols,
        baseline: contributions.baseline,

      },
      uwJson: underwriting,
      obJson: {
        observations: storeHooks.getObservationQuestions(),
      },
    };
    if (actionType === 'edit' || actionType === 'copy') {
      body.userId = store.state.user.userId,
        body.formId = formId;
    }
    return body;
  },
  getYesClaimDataFull(state, data, actionType) {
    return {
      ...this.getCustomerInfoData(actionType),
      userId: state.user.userId,
      formId: data.formId,
      approvalStatus: 2,
      outputPageUrl: "sample2",
      careNavigation: state.pop_health_dial_yes.q.health_care_navigation === "No" ? 0 : 1,
      diabetes: state.pop_health_dial_yes.q.chronic_care_management_diabetes === "No" ? 0 : 1,
      hypertension: state.pop_health_dial_yes.q.chronic_care_management_hypertension === "No" ? 0 : 1,
      womensHealth: state.pop_health_dial_yes.q.maternity_care_management === "No" ? 0 : 1,
      mentalHealth: state.pop_health_dial_yes.q.enhanced_mh_sa_services === "No" ? 0 : 1,
      musculoskeletal: state.pop_health_dial_yes.q.chronic_care_management_msk === "No" ? 0 : 1,
      cancerCenters: state.pop_health_dial_yes.q.center_of_excellence_cancer === "No" ? 0 : 1,
      surgicalCenters: state.pop_health_dial_yes.q.center_of_excellence_surgical === "No" ? 0 : 1,
      highPerformingNetwork: state.pop_health_dial_yes.q.high_performing_network === "No" ? 0 : 1,
      incentivizedPlanDesign: state.pop_health_dial_yes.q.incentivized_plan_design === "No" ? 0 : 1,
      physicianEngagement: state.pop_health_dial_yes.q.physician_engagement === "No" ? 0 : 1,
      pharmacyCarveOut: state.pop_health_dial_yes.q.pharmacy_carve_out === "No" ? 0 : 1,
      patientAssistanceProgram: state.pop_health_dial_yes.q.patient_assistance_program === "No" ? 0 : 1,
      reinsuranceCarveOut: state.pop_health_dial_yes.q.reinsurance_carve_out === "No" ? 0 : 1,
      fullyInsuredTotalCost: state.pop_health_dial_yes.fully_insured_total_cost,
      baselineSelfFundedTotalCost: state.pop_health_dial_yes.self_funded_total_cost,
      bestInBreed: state.pop_health_dial_yes.additonal_programs_total_cost,
      healthOutcomesOptimization: state.pop_health_dial_yes.graph_percentage,
      baseline: state.wip_graph_form_yes.pepy.baselineData,
      costMitigationStrategy: state.wip_graph_form_yes.pepy.costMitigationStrategyData,
      baselineClaims: state.wip_graph_form_yes.spend.baselineClaims,
      baselineClaimsCosts: state.wip_graph_form_yes.spend.baselineFixedCost,
      costMitigationStrategyClaims: state.wip_graph_form_yes.spend.costMitigationStrategyClaims,
      costMitigationStrategyFixedCosts: state.wip_graph_form_yes.spend.costMitigationStrategyFixedCosts,
      
      baseLineTrend: state.wip_graph_form_yes.compositeBaselineTrend,//R2
      costMitigationTrend: state.wip_graph_form_yes.compositeBestInClass, //R3

      totalCost: state.wip_graph_form_yes.mitigation_cost,
      dcJson: state.contributions_edit.dc,
      erJson: state.contributions_edit.er_parcentage,
    };

  },
};
