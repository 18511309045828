// hooks
import {helperHooks} from '@/hooks/helper.hooks';

export const customerInfo = {
  get() {
    return helperHooks.deepCopy({
      type: 2,
      medical: 0,
      effective_date: '',
      claimCostStartDate: '',
      company_name: '',
      funding_type: 1,
      eligible_employees: '',
      enrolled_employees: '',
      claims_data_available: 0,
      industry: 0,
      product: '',
      highCostClaimsThreshold: 0,
    });
  },
};
