export const helperHooks = {
  deepCopy(originalItem) {
    if (!originalItem) return originalItem;
    // todo change to a simple form
    return JSON.parse(JSON.stringify(originalItem));
  },


  getBool(value) {
    if (value === true) return true;
    if (value === 'true') return true;
    if (value === 1) return true;
    if (value === '1') return true;
    return false;
  }
};
