// store
import store from '@/store';
// hooks
import {storeHooks} from '@/hooks/store.hooks';
import {PopHealthService as PopHealthService} from "@/services/domain/popHealth.service";


export const popHealthDials = {
  handleClickDial(actionType, dialName, dialValue, vm) {

    // debugger;
    if (!dialName) return;
    if (dialValue === 0) { // NO
      PopHealthService.setAllToZero(vm, dialName);
      return;
    }

    // if value == 1 => YES
    const enrolledEmployees = storeHooks.getInputForm(actionType).enrolled_employees;
    const fullyInsuredTotalCost = vm.pop_health_dial.fully_insured_total_cost;

    const engagement = store.state.programConstants[dialName]['engagement'];
    const prevalence = store.state.programConstants[dialName]['prevalence'];
    const savingsY1 = store.state.programConstants[dialName]['claimsSavings'];
    const savingsY2 = store.state.programConstants[dialName]['claimsSavingsY2'];
    const savingsY3 = store.state.programConstants[dialName]['claimsSavingsY3'];
    const fixedY1 = store.state.programConstants[dialName]['fixed'];
    const fixedY2 = store.state.programConstants[dialName]['fixedY2'];
    const fixedY3 = store.state.programConstants[dialName]['fixedY3'];

    console.log(`On dial click`)
    const o = {engagement, prevalence, savingsY1, savingsY2, savingsY3, fixedY1, fixedY2, fixedY3}
    console.table(o)


    // do calculations
    console.log(`M values`)

    // POP HEALTH DIALS Claims Savings
    vm.m[dialName] = PopHealthService.getCharacterValueM(dialName, savingsY1, fixedY1, fullyInsuredTotalCost, enrolledEmployees, prevalence, engagement);
    vm.n[dialName] = vm.m[dialName] //|| PopHealthService.getCharacterValueN(vm.m[dialName], fixedY2, enrolledEmployees);
    vm.o[dialName] = vm.m[dialName] //|| PopHealthService.getCharacterValueO(vm.n[dialName], fixedY3, enrolledEmployees);
    vm.p[dialName] = vm.o[dialName];
    vm.q[dialName] = vm.o[dialName];

    // POP HEALTH DIALS Fixed Cost
    vm.g[dialName] = PopHealthService.getCharacterValueG(vm.m[dialName], dialName, savingsY1, fullyInsuredTotalCost);
    vm.h[dialName] = vm.g[dialName] //|| PopHealthService.getCharacterValueH(vm.n[dialName], vm.g[dialName], vm.i[dialName], dialName, savingsY2, fullyInsuredTotalCost)
    vm.i[dialName] = vm.g[dialName] //|| PopHealthService.getCharacterValueI(vm.o[dialName], vm.h[dialName], dialName, savingsY3, fullyInsuredTotalCost);
    vm.j[dialName] = vm.g[dialName] //|| PopHealthService.getCharacterValueJ(vm.i[dialName], dialName);
    vm.k[dialName] = vm.g[dialName] //|| PopHealthService.getCharacterValueK(vm.i[dialName], dialName);

    // POP HEALTH DIALS Net Savings
    vm.s[dialName] = vm.g[dialName] - vm.m[dialName];
    vm.t[dialName] = vm.h[dialName] - vm.n[dialName];
    vm.u[dialName] = vm.i[dialName] - vm.o[dialName];
    vm.v[dialName] = vm.j[dialName] - vm.p[dialName];
    vm.w[dialName] = vm.k[dialName] - vm.q[dialName];


    // debugger;
  }
}
