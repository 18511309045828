// store
import store from '@/store';
import {nonPersistentStore} from '@/store/non-persistent.store';

export const storeHooks = {
  validateActionType(actionType, availableOptions = ['create', 'edit', 'copy']) {
    if (!availableOptions.find((option) => option === actionType)) {
      console.error('Invalid actionType', actionType);
      console.trace();
    }
  },
  validateClaimData(claimData) {
    if (claimData !== 'yes' && claimData !== 'no') {
      console.error('Invalid claimData', claimData);
      console.trace();
    }
  },
  getContributions(actionType) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.contributions;
    if (actionType === 'edit') return store.state.contributions_edit;
    if (actionType === 'copy') return store.state.contributions_copy;
  },
  getInputForm(actionType) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.inputForm;
    if (actionType === 'edit') return store.state.input_form_edit;
    if (actionType === 'copy') return store.state.input_form_copy;
  },
  getUnderwriting(actionType) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.underwriting;
    if (actionType === 'edit') return store.state.underwriting_edit;
    if (actionType === 'copy') return store.state.underwriting_copy;
  },
  getObservationQuestions() {
    return nonPersistentStore.state.observationQuestions;
  },
  getPopHealthDial(actionType, claimData) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.pop_health_dial;
    if (actionType === 'copy') return store.state.pop_health_dial_copy;
    if (actionType === 'edit') {
      this.validateClaimData(claimData);
      if (claimData === 'yes') return store.state.pop_health_dial_yes;
      if (claimData === 'no') return store.state.pop_health_dial_edit;
    }
  },
  getWipGraphForm(actionType, claimData) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.wip_graph_form;
    if (actionType === 'copy') return store.state.wip_graph_form_copy;
    if (actionType === 'edit') {
      this.validateClaimData(claimData);
      if (claimData === 'yes') return store.state.wip_graph_form_yes;
      if (claimData === 'no') return store.state.wip_graph_form_edit;
    }
  },
  getCalc(actionType, claimData) {
    this.validateActionType(actionType);
    if (actionType === 'create') return store.state.calc;
    if (actionType === 'copy') return store.state.calc_copy;
    if (actionType === 'edit') {
      // debugger;
      this.validateClaimData(claimData);
      if (claimData === 'yes') return store.state.calc_yes;
      if (claimData === 'no') return store.state.calc_edit;
    }
  },

  setUnderwriting(actionType, newValue) {
    this.validateActionType(actionType);
    if (actionType === 'create') store.state.underwriting = newValue;
    if (actionType === 'edit') store.state.underwriting_edit = newValue;
    if (actionType === 'copy') store.state.underwriting_copy = newValue;
  },
}
