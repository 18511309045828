// hooks
import { helperHooks } from '@/hooks/helper.hooks';

export const contributionBaseline = {
  get({ includeIdPerPlan = false } = {}) {
    return helperHooks.deepCopy({
      plan1: this.getPlan({ includeId: includeIdPerPlan }),
      plan2: this.getPlan({ includeId: includeIdPerPlan }),
      plan3: this.getPlan({ includeId: includeIdPerPlan }),
      plan4: this.getPlan({ includeId: includeIdPerPlan }),
      plan5: this.getPlan({ includeId: includeIdPerPlan }),
      monthly_cost_lives: 0,
      monthly_cost_total: 0,
      monthly_cost_employer: 0,
      monthly_cost_employee: 0,
      monthly_cost_employer_percentage: 0,
      PEPM_total: 0,
      PEPM_employer: 0,
      PEPM_employee: 0,
      total_contribution: 0,
      employer_portion: 0,
    });
  },
  getPlan({ includeId = false } = {}) {
    const plan = {
      lives: {
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      },
      sum_lives: 0,
      total: {
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      },
      sum_total: 0,
      employer: {
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      },
      sum_employer: 0,
      employee: {
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      },
      sum_employee: 0,
      employer_percentage: {
        percentage1: 0,
        percentage2: 0,
        percentage3: 0,
        percentage4: 0,
      },
      sum_employer_percentage: 0,
      tier_ratio: {
        ratio1: 0,
        ratio2: 0,
        ratio3: 0,
      },
      total_cost: 0,
      employer_cost: 0,
      employee_cost: 0,
      plan_design: {
        product_type: "PPO",
        individual_deductible: 0,
        family_deductible: 0,
        co_insurance: 0,
        individual_OOPM: 0,
        family_OOPM: 0,
        physician_visit: 0,
        specialist_visit: 0,
        rx_generic: 0,
        rx_preferred_brand: 0,
        rx_non_preferred_brand: 0,
      },
    }
    if (includeId) {
      plan.id = "";
    }
    return helperHooks.deepCopy(plan);
  },
};
