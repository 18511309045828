// hooks
import {contributionBaseline} from './default-data/defaultData--contributionBaseline';
import {customerInfo} from './default-data/defaultData--customerInfo';
import {popHealthDials} from './default-data/defaultData--popHealthDials';
import {programs} from './default-data/defaultData--programs';
import {underwriting} from './default-data/defaultData--underwriting';

export const defaultDataHooks = {
  contributionBaseline,
  customerInfo,
  popHealthDials,
  programs,
  underwriting,
};
