export class CustomerService {

  static initData = () => ({
    type: 2,
    medical: 0,
    effective_date: '',
    claimCostStartDate: '',
    company_name: '',
    funding_type: 1,
    eligible_employees: '',
    enrolled_employees: '',
    claims_data_available: 0,
    industry: "",
    product: "",
    industryList: [],
    fundingList: [],
    clientOrProspect: [],
    productList: []
  })

}
