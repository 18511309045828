// store
import store from '@/store';
// hooks
import { storeHooks } from '@/hooks/store.hooks';
import { helperHooks } from '@/hooks/helper.hooks';

export const programs = {
  getEmptyProgramConstants() {
    const values = {};
    this.getProgramConstantsNames().forEach((constantName) => {
      values[constantName] = 0;
    });
    return helperHooks.deepCopy(values);
  },
  getProgramConstantsNames() {
    return helperHooks.deepCopy([
      'fixed',
      'fixedY2',
      'fixedY3',
      'claimsSavings',
      'claimsSavingsY2',
      'claimsSavingsY3',
      'prevalence',
      'engagement',
      'netRoi',
      'trendBend',
    ]);
  },
  getProgramNames(addSelfFund = false) {
    const programs = [
      'center_of_excellence_cancer',
      'center_of_excellence_surgical',
      'high_performing_network',
      'patient_assistance_program',
      'pharmacy_carve_out',
      'reinsurance_carve_out',
      'chronic_care_management_diabetes',
      'chronic_care_management_hypertension',
      'chronic_care_management_msk',
      'health_care_navigation',
      'enhanced_mh_sa_services',
      'maternity_care_management',
      'physician_engagement',
      'incentivized_plan_design',
    ];
    if (addSelfFund) programs.unshift('self_funded');
    return helperHooks.deepCopy(programs);
  },
};
